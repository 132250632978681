import Q from 'q';
import moment from 'moment';
import web_common_buyerTourCutOrder_orderTeamDetails from '@/lib/data-service/default/web_common_buyerTourCutOrder_orderTeamDetails';
import web_common_tourCutOrder_uploadTravellers from '@/lib/data-service/default/web_common_tourCutOrder_uploadTravellers';

export default {
  components: {},
  data() {
    return {
      moment,
      orderInfoData: {},
      form: {
        id: '',
        //联系人
        linkMan: '',
        //电话
        linkManPhone: '',
        //邮箱
        linkManEmail: '',
        //备注
        remark: '',
        //出游人列表
        travellerList: [],
        //其他费用
        otherExpensesList: [],
      },
      allocation: {
        isFirster: true,
        //预定总数
        totalNum: 0,
        reserveLength: 0,
        reserveList: [],
        //单个出游人的需填信息
        travellerObj: {
          //是否成人
          crowdType: '',
          //出游人姓名
          touristName: '',
          //出游人性别
          touristGender: '',
          // 证件类型
          certificatesType: '',
          //证件号码
          certificatesNumber: '',
          //联系方式
          touristContactInformation: '',
          //出生年月日
          birthDate: ''
        },
        FeeAmountList: {},
        //是否成年人
        crowdType: [{
            label: '成人',
            value: 1
          },
          {
            label: '儿童占床',
            value: 4
          },
          {
            label: '儿童不占床',
            value: 2
          },
          {
            label: '老人',
            value: 3
          },
        ],
        //性别
        touristGender: [ //
          {
            label: '男',
            value: 0
          },
          {
            label: '女',
            value: 1
          }
        ],
        //证件类型
        certificatesType: [
          {
            label: '身份证',
            value: 1
          },
          {
            label: '护照',
            value: 2
          },
          {
            label: '军官证',
            value: 3
          },
          {
            label: '学生证',
            value: 4
          },
          {
            label: '其他',
            value: 5
          }
        ],
      }
    };
  },
  computed: {},
  methods: {
    req_cut_order_details(params) {
      const _this = this;
      const form = _this.form;
      const allocation = _this.allocation;
      Q.when()
        .then(function () {
          return web_common_buyerTourCutOrder_orderTeamDetails(params);
        })
        .then(function (res) {
          //详细情况
          _this.orderInfoData = res.result;
          //联系人姓名
          form.linkMan = res.result.name;
          //联系人电话
          form.linkManPhone = res.result.phone;
          //联系人邮箱
          form.linkManEmail = res.result.email;
          //备注
          form.remark = res.result.descreiption;
          //出游人列表
          form.travellerList = res.result.tourTravellerInformations;
          if (!form.travellerList.length) {
            form.travellerList.push(JSON.parse(JSON.stringify(_this.allocation.travellerObj)));
          }
          //预订总人数
          allocation.totalNum = res.result.totalNum;
          //
          allocation.FeeAmountList.adultSingleAmount = res.result.adultSingleAmount;
          allocation.FeeAmountList.childrenSingleAmount = res.result.childrenSingleAmount;
          allocation.FeeAmountList.agedSingleAmount = res.result.agedSingleAmount;
          allocation.FeeAmountList.childrenBedSingleAmount = res.result.childrenBedSingleAmount;
          form.otherExpensesList = res.result.tourOtherExpenses.map(function (item) {
            let newItem = {};
            newItem = item;
            return newItem;
          });
        });
    },
    req_upload_namelist(params) {
      return Q.when().then(function () {
        return web_common_tourCutOrder_uploadTravellers(params);
      });
    },
    //初始化请求
    initialize(query) {
      const params = {
        id: query.id
      };
      this.form.id = query.id;
      this.req_cut_order_details(params);
    },
    //删减出游人
    delete_the_visitors(index) {
      this.form.travellerList.splice(index, 1);
    },
    //添加出游人
    add_the_visitors() {
      this.form.travellerList.push(
        JSON.parse(JSON.stringify(this.allocation.travellerObj))
      );
    },
    //提交名单
    commit_name_list() {
      const _this = this;
      const form = _this.form;
      const phoneReg = /^1[3456789]\d{9}$/;
      const emailReg = /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/;
      const certificatesNumberReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!form.linkMan) {
        this.$message({
          message: '请输入联系人！',
          type: 'warning'
        });
        return;
      }

      if (!form.linkManPhone) {
        this.$message({
          message: '请输入联系电话！',
          type: 'warning'
        });
        return;
      }

      if (!phoneReg.test(form.linkManPhone)) {
        this.$message({
          message: '请输入正确的11手机号码！',
          type: 'warning'
        });
        return;
      }

      if (!form.linkManEmail) {
        this.$message({
          message: '请输入联系邮箱号！',
          type: 'warning'
        });
        return;
      }

      if (!emailReg.test(form.linkManEmail)) {
        this.$message({
          message: '请输入正确的邮箱号！',
          type: 'warning'
        });
        return;
      }

      for (let i = 0; i < form.travellerList.length; i++) {
        const item = form.travellerList[i];
        if (!item.crowdType) {
          _this.$message({
            message: '请选择出游人类型！',
            type: 'warning'
          });
          return;
        }

        if (!item.touristName) {
          _this.$message({
            message: '请输入出游人姓名！',
            type: 'warning'
          });
          return;
        }

        if (!item.touristGender && item.touristGender !== 0) {
          _this.$message({
            message: '请输入出游人性别！',
            type: 'warning'
          });
          return;
        }

        if (!item.certificatesType) {
          _this.$message({
            message: '请选择证件类型！',
            type: 'warning'
          });
          return;
        }

        if (!item.certificatesNumber) {
          _this.$message({
            message: '请输入证件编号！',
            type: 'warning'
          });
          return;
        }

        if (item.certificatesType === 1 && !item.certificatesNumber) {
          _this.$message({
            message: '请输入证件编号！',
            type: 'warning'
          });
          return;
        }


        if (item.certificatesType === 1 && !certificatesNumberReg.test(item.certificatesNumber)) {
          _this.$message({
            message: '请输入正确的身份证号！',
            type: 'warning'
          });
          return;
        }

        if (item.touristContactInformation && !phoneReg.test(item.touristContactInformation)) {
          _this.$message({
            message: '请输入正确的出游人联系电话！',
            type: 'warning'
          });
          return;
        }

      }

      const params = form;
      params.orderNo = _this.orderInfoData.orderNo;
      Q.when()
        .then(function () {
          return _this.req_upload_namelist(_this.form);
        })
        .then(function (res) {
          _this.$message({
            message: '出游人名单上传成功',
            type: 'success'
          });
          setTimeout(function () {
            _this.$router.back(-1);
          }, 1000);
        });
    },
    //身份证生日联动
    cardNum(index) {
      const item = this.form.travellerList[index];
      const certificatesType = item.certificatesType;
      const certificatesNumber = item.certificatesNumber;
      const certificatesNumberReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

      if (certificatesType === 1 && certificatesNumberReg.test(certificatesNumber)) {
        if (certificatesNumber.length === 15) {
          item.birthDate = '19' + certificatesNumber.slice(6, 8) + '-' + certificatesNumber.slice(8, 10) + '-' + certificatesNumber.slice(10, 12);
        } else if (certificatesNumber.length === 18) {
          item.birthDate = certificatesNumber.slice(6, 10) + '-' + certificatesNumber.slice(10, 12) + '-' + certificatesNumber.slice(12, 14);
        }
      }
    },
    go_back() {
      this.$router.back(-1);
    }
  },
  watch: {
    form: {
      handler: function (newVal) {
        const FeeAmountList = this.allocation.FeeAmountList;
        newVal.otherExpensesList.forEach(function (item) {
          item.costTotal = item.costMoneyAmount * item.costNumber;
        })

        newVal.travellerList.forEach(function (item) {

          switch (item.crowdType) {
            case 1:
              item.tourFeeAmount = FeeAmountList.adultSingleAmount;
              break;
            case 2:
              item.tourFeeAmount = FeeAmountList.childrenSingleAmount;
              break;
            case 3:
              item.tourFeeAmount = FeeAmountList.agedSingleAmount;
              break;
            case 4:
              item.tourFeeAmount = FeeAmountList.childrenBedSingleAmount;
              break;
          }
        })
      },
      deep: true,
    },
    allocation: {
      handler: function (newVal, oldVal) {

      },
      deep: true
    }
  },
  mounted() {

  },
  activated() {
    this.initialize(this.$route.query);
  },
  deactivated() {

  }
};
